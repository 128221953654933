<template>
  <b-container class="m-o w-100" fluid>
    <header-slot>
      <template #actionsButtons>
        <portal-target name="actionsButtons_header" class="h-100"/>
      </template>
      <template #actions>
        <!-- This component is using PortalVue, look for the action buttons in the corresponding components -->
        <portal-target name="actions_header"> </portal-target>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debt-solution-settings-1' }"
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == 'debt-solution-settings-1'"
        :link-classes="['px-3', bgTabsNavs]"
        >DEBT SOLUTION 
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debt-solution-settings-bank-accounts' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == 'debt-solution-settings-bank-accounts'"
        >BANK ACCOUNTS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debt-solution-court-info' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == 'debt-solution-court-info'"
        >COURT INFO
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view />
    </b-card>
  </b-container>
</template>

<script>
export default {
};
</script>